import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "../styles/swiper.css";

const MainPage = () => {
  return (
    <Layout>
      <div className="top_section">
        <div className="bg"></div>
        <div className="inner">
          <div className="top_text">Unprecedented 3D solution</div>
          <p>
            Creating innovative value through advanced 3D solution of
            construction, operational asset industry, smart city and immersive
            contents.
          </p>
          <div className="btns">
            <a href="/about">More About Us</a>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="main_section">
          <div className="bg_white">
            <section id="technology">
              <h2>Technology</h2>
            </section>
            <p>
              <strong style={{ fontWeight: "bold" }}>Conworth Engine</strong> :
              3D data/rendering engine for high-performance scalable server
              operation and massive 3D data rendering.
            </p>
            <div
              className="tech__conworth_engine"
              style={{ display: "block", margin: "0px auto", width: 1280 }}
            >
              <StaticImage src={"../images/tech__conworth_engine.png"} />
            </div>
            <p>
              <strong style={{ fontWeight: "bold" }}>
                Massive 3D Rendering Test
              </strong>{" "}
              : Conworth engine is particularly specialized for massive
              computation and high-quality rendering for massive 3D data.
            </p>
            <br />
            <div
              className="tech__massive3d_test"
              // style={{ display: "block", margin: "0px auto", width: 1280 }}
            >
              <StaticImage src={"../images/img_tech_massive3d.png"} />
            </div>
            <p>
              <strong style={{ fontWeight: "bold" }}>
                Digital twinning technology
              </strong>
              : Integrated with proprietary big data processing system, robot,
              IoT, BIM and point cloud.
            </p>
            <ul className="thch_info">
              <li className="ico01">
                <strong>Full 3D</strong>
                We deliver and manage seamless indoor-outdoor full 3D data for
                industrial sites.
              </li>
              <li className="ico02">
                <strong>High Performance Computing</strong>
                Compared with commercial cloud system, our proprietary edge
                cloud system offers low-cost operative and scalable solution.
              </li>
              <li className="ico03">
                <strong>Web/App-Based Platform</strong>
                Our web/app-based data platform can be used as an efficient
                communication and collaboration tool in construction sites and
                physical asset management.
              </li>
              <li className="ico04">
                <strong>Robot Integration</strong>
                Integration of cutting-edge robot technology with our data
                platform opens a new horizon of remote monitoring of
                construction sites and physical assets.
              </li>
            </ul>
          </div>
          <div className="bg_gray">
            <section id="solutions">
              <h2>Solutions</h2>
            </section>
            <p>
              Industry-leading data platforms integrated with A-Z processes in
              managing construction sites and operational assets of capital
              intensive industry: C-Eagle, R-Eagle, T-Eagle, H-Eagle.
            </p>
            <div className="solution-swiper-container-wrapper-all">
              <div className="solution-swiper-container-wrapper">
                <Swiper
                  className="solution-swiper-container"
                  modules={[EffectCoverflow, Navigation, Pagination]}
                  pagination={{
                    clickable: true,
                    el: ".swiper-pagination",
                  }}
                  navigation={{
                    clickable: true,
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  autoplay={false}
                  loop={true}
                  speed={1000}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  effect={"coverflow"}
                  coverflowEffect={{
                    rotate: 0,
                    depth: 250,
                    modifier: 1.5,
                    stretch: 320,
                    slideShadows: false,
                  }}
                  breakpoints={{
                    200: { spaceBetween: 30 },
                  }}
                >
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_c">
                          <strong>C-Eagle</strong>
                          <span>
                            <StaticImage src="../images/C-Eagle_1.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_c">
                          <strong>C-Eagle</strong>
                          <span>
                            <StaticImage src="../images/C-Eagle_2.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_c">
                          <strong>C-Eagle</strong>
                          <span>
                            <StaticImage src="../images/C-Eagle_3.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution_swiper_control">
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next"></div>
                  </div>
                </Swiper>
                <Swiper
                  className="solution-swiper-container"
                  modules={[EffectCoverflow, Navigation, Pagination]}
                  pagination={{
                    clickable: true,
                    el: ".swiper-pagination",
                  }}
                  navigation={{
                    clickable: true,
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  autoplay={false}
                  loop={true}
                  speed={1000}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  effect={"coverflow"}
                  coverflowEffect={{
                    rotate: 0,
                    depth: 250,
                    modifier: 1.5,
                    stretch: 320,
                    slideShadows: false,
                  }}
                  breakpoints={{
                    200: { spaceBetween: 30 },
                  }}
                >
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_r">
                          <strong>R-Eagle</strong>
                          <span>
                            <StaticImage src="../images/R-Eagle_1.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_r">
                          <strong>R-Eagle</strong>
                          <span>
                            <StaticImage src="../images/R-Eagle_2.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_r">
                          <strong>R-Eagle</strong>
                          <span>
                            <StaticImage src="../images/R-Eagle_3.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution_swiper_control">
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next"></div>
                  </div>
                </Swiper>
              </div>
              <div className="solution-swiper-container-wrapper">
                <Swiper
                  className="solution-swiper-container"
                  modules={[EffectCoverflow, Navigation, Pagination]}
                  pagination={{
                    clickable: true,
                    el: ".swiper-pagination",
                  }}
                  navigation={{
                    clickable: true,
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  autoplay={false}
                  loop={true}
                  speed={1000}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  effect={"coverflow"}
                  coverflowEffect={{
                    rotate: 0,
                    depth: 250,
                    modifier: 1.5,
                    stretch: 320,
                    slideShadows: false,
                  }}
                  breakpoints={{
                    200: { spaceBetween: 30 },
                  }}
                >
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_t">
                          <strong>T-Eagle</strong>
                          <span>
                            <StaticImage src="../images/T-Eagle_1.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_t">
                          <strong>T-Eagle</strong>
                          <span>
                            <StaticImage src="../images/T-Eagle_2.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_t">
                          <strong>T-Eagle</strong>
                          <span>
                            <StaticImage src="../images/T-Eagle_3.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution_swiper_control">
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next"></div>
                  </div>
                </Swiper>
                <Swiper
                  className="solution-swiper-container"
                  modules={[EffectCoverflow, Navigation, Pagination]}
                  pagination={{
                    clickable: true,
                    el: ".swiper-pagination",
                  }}
                  navigation={{
                    clickable: true,
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  autoplay={false}
                  loop={true}
                  speed={1000}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  effect={"coverflow"}
                  coverflowEffect={{
                    rotate: 0,
                    depth: 250,
                    modifier: 1.5,
                    stretch: 320,
                    slideShadows: false,
                  }}
                  breakpoints={{
                    200: { spaceBetween: 30 },
                  }}
                >
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_h">
                          <strong>H-Eagle</strong>
                          <span>
                            <StaticImage src="../images/H-Eagle_1.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_h">
                          <strong>H-Eagle</strong>
                          <span>
                            <StaticImage src="../images/H-Eagle_2.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution-swiper-wrapper">
                    <SwiperSlide className="solution-swiper-slide">
                      <ul className="sol_info">
                        <li className="type_h">
                          <strong>H-Eagle</strong>
                          <span>
                            <StaticImage src="../images/H-Eagle_3.png" alt="" />
                          </span>
                        </li>
                      </ul>
                    </SwiperSlide>
                  </div>
                  <div className="solution_swiper_control">
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-button-next"></div>
                  </div>
                </Swiper>
              </div>
            </div>
            {/* <ul className="sol_info">
              <li className="type_c">
                <strong>C-Eagle</strong>
                <span>
                  <StaticImage src="../images/4-1.png" alt="" />
                </span>
              </li>
              <li className="type_r">
                <strong>R-Eagle</strong>
                <span>
                  <StaticImage src="../images/4-2.png" alt="" />
                </span>
              </li>
            </ul>
            <ul className="sol_info">
              <li className="type_h">
                <strong>T-Eagle</strong>
                <span>
                  <StaticImage src="../images/4-3.png" alt="" />
                </span>
              </li>
              <li className="type_c">
                <strong>H-Eagle</strong>
                <span>
                  <StaticImage src="../images/4-4.png" alt="" />
                </span>
              </li>
            </ul> */}
          </div>
          <div className="ceo_info">
            <div className="inner">
              <div className="photo">
                <StaticImage src="../images/ceo_photo.png" alt="" />
              </div>
              <div className="txt_box">
                <p>
                  The future of the construction industry depends on
                  productivity improvement through digital transformation. This
                  is directly related to the level of data collection, storage,
                  management, analysis and information sharing of construction
                  sites.
                </p>
                <div className="sign">
                  <strong>Joon Heo</strong>
                  <span>Founder / CEO, Conworth Inc.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg_white">
            <section id="about">
              <h2>Partners</h2>
            </section>
            <ul className="biz_list">
              <li className="img1">
                <StaticImage
                  style={{
                    maxWidth: "300px",
                    // maxHeight: "100px",
                    // width: "100%",
                  }}
                  src="../images/logo_yonsei.png"
                  alt="연세대학교"
                />
              </li>
              <li className="img2">
                <StaticImage
                  style={{
                    maxWidth: "300px",
                    // maxHeight: "100px",
                    // width: "100%",
                  }}
                  src="../images/logo_boston.png"
                  alt="Boston Dynamics"
                />
              </li>
              <li className="img3">
                <StaticImage
                  style={{
                    maxWidth: "200px",
                    // maxHeight: "100px",
                    // width: "100%",
                  }}
                  src="../images/logo_platinum.png"
                  alt="플래티넘기술투자"
                />
              </li>
              <li className="img4">
                <StaticImage
                  style={{
                    maxWidth: "200px",
                    // maxHeight: "100px",
                    // width: "100%",
                  }}
                  src="../images/logo_fargo.png"
                  alt="FARO"
                />
              </li>
              <li className="img5">
                <StaticImage src="../images/logo_wipco.png" alt="wipco" />
              </li>
              <li className="img6">
                <StaticImage src="../images/logo_uok.png" alt="UOK" />
              </li>
              <li className="img7">
                <StaticImage src="../images/Clients_KODIT_1.png" alt="KODIT" />
              </li>
              <li className="img8">
                <StaticImage
                  // style={{
                  //   marginLeft: "10px",
                  // }}
                  src="../images/Partners_Kwater_resize.png"
                  alt="Partners_Kwater"
                />
              </li>
              <li className="img9">
                <StaticImage
                  src="../images/Partners_LH.png"
                  alt="Partners_LH"
                />
              </li>
              <li className="img10">
                <StaticImage
                  style={{
                    marginTop: "30px",
                  }}
                  src="../images/Partners_도로공사.png"
                  alt="Partners_도로공사"
                />
              </li>
            </ul>
          </div>
          <div className="bg_gray">
            <h2>Clients</h2>
            <ul className="biz_list">
              <li className="img01">
                <StaticImage
                  style={{
                    maxWidth: "270px",
                  }}
                  src="../images/logo_hy.png"
                  alt="현대엔지니어링"
                />
              </li>
              <li className="img02">
                <StaticImage
                  style={{
                    maxWidth: "250px",
                  }}
                  src="../images/logo_hy_eng.png"
                  alt="현대건설"
                />
              </li>
              <li className="img03">
                <StaticImage
                  style={{
                    maxWidth: "300px",
                  }}
                  src="../images/logo_ss_cnt.png"
                  alt="삼성 C$amp;T"
                />
              </li>
              <li className="img04">
                <StaticImage
                  src="../images/logo_lotte_enc.png"
                  alt="롯데 E$amp;C"
                />
              </li>
              <li className="img05">
                <StaticImage
                  style={{
                    maxWidth: "300px",
                    // width: "80%",
                  }}
                  src="../images/logo_posco.png"
                  alt="포스코 E$amp;C"
                />
              </li>
              <li className="img06">
                <StaticImage
                  style={{
                    maxWidth: "300px",
                    // maxHeight: "100px",
                    // width: "100%",
                  }}
                  src="../images/logo_kolon.png"
                  alt="코오롱 글로벌"
                />
              </li>
              <li className="img07">
                <StaticImage
                  style={{
                    maxWidth: "300px",
                    // maxHeight: "100px",
                    // width: "100%",
                  }}
                  src="../images/logo_ministry.png"
                  alt="국방부"
                />
              </li>
              <li className="img08">
                <StaticImage
                  style={{
                    maxWidth: "300px",
                    // maxHeight: "100px",
                    // width: "100%",
                  }}
                  src="../images/logo_incheon.png"
                  alt="인천시"
                />
              </li>
              <li className="img09">
                <StaticImage src="../images/logo_uljin.png" alt="울진군" />
              </li>
              <li className="img010">
                <StaticImage
                  style={{
                    maxWidth: "200px",
                    // maxHeight: "100px",
                    // width: "100%",
                    marginLeft: "20px",
                  }}
                  src="../images/7-1.png"
                  alt="LG화학"
                />
              </li>
              <li className="img011">
                <StaticImage
                  style={{
                    maxWidth: "200px",
                    // maxHeight: "100px",
                    // width: "70%",
                    // marginLeft: "20px",
                  }}
                  src="../images/Clients_AutoEver.png"
                  alt="Clients_AutoEver"
                />
              </li>
            </ul>
          </div>
          <div className="news_box" id="news">
            <section id="news">
              <h2>News</h2>
            </section>
            <Swiper
              className="swiper-container"
              modules={[EffectCoverflow, Navigation, Pagination]}
              pagination={{
                clickable: true,
                el: ".swiper-pagination",
              }}
              navigation={{
                clickable: true,
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              autoplay={false}
              loop={true}
              speed={1000}
              centeredSlides={true}
              slidesPerView={"auto"}
              effect={"coverflow"}
              coverflowEffect={{
                rotate: 0,
                depth: 250,
                modifier: 1.5,
                stretch: 320,
                slideShadows: true,
              }}
              breakpoints={{
                700: { spaceBetween: 30 },
              }}
            >
              <div className="swiper-wrapper">
                <SwiperSlide>
                  <div className="news_list" style={{ width: "550px" }}>
                    <a
                      href="https://m.dnews.co.kr/m_home/view.jsp?idxno=202312061912020000422"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <center>
                        <span
                          className="thumb"
                          style={{ width: "350px", marginTop: "20px" }}
                        >
                          <StaticImage src="../images/news_thumb6.png" alt="" />
                        </span>
                      </center>
                      <div className="cont">
                        <strong className="subject">
                          “건설 유지관리 시장 확장…데이터 통해 새 가치 창출”
                        </strong>
                        <p>
                          “건설산업의 디지털 전환에서 기술적ㆍ경제적 관점의
                          어려움을 극복하려면, 거버넌스를 활용해야 한다. 특히,
                          현 건설 프로세스 거버넌스의 정점에 있는 발주자가 DX를
                          주도해야 한다.” 허준 연세대학교 건설환경공학과 교수는
                          최근 인터뷰에서 건설산업의 디지털 전환(DXㆍDigital
                          Transformation)에 대해 이렇게 강조했다.
                        </p>
                        <span className="date">2023년 12월 07일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="news_list" style={{ width: "550px" }}>
                    <a
                      href="https://m.dnews.co.kr/m_home/view.jsp?idxno=202311061623382650054"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <center>
                        <span
                          className="thumb"
                          style={{ width: "350px", marginTop: "20px" }}
                        >
                          <StaticImage src="../images/news_thumb7.jpg" alt="" />
                        </span>
                      </center>
                      <div className="cont">
                        <strong className="subject">
                          “건설산업 DX, 발주자가 주도해야… 인프라·건축도 전환
                          늦으면 도태”
                        </strong>
                        <p>
                          컨워스의 허준 대표는 아직 디지털 전환이 되지 않은
                          건설산업 유지관리 분야에 뛰어든 이유에 대해 "현장을
                          포함한 데이터에서 가치를 창출해낼 수 있는지에 따라
                          디지털 전환에 성공하느냐 못하느냐 차이가 날 것”이라며,
                          “대규모 플랜트나 팹, 현장 유지관리를 시작으로 디지털
                          전환이 확산될 것이다. 여기에서 공간데이터가 매우
                          중요한 역할을 할 것”이리고 강조했다.
                        </p>
                        <span className="date">2023년 11월 09일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="news_list" style={{ width: "550px" }}>
                    <a
                      href="https://m.dnews.co.kr/m_home/view.jsp?idxno=202312061818300120415"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="thumb">
                        <StaticImage src="../images/news_thumb8.jpg" alt="" />
                      </span>
                      <div className="cont">
                        <strong className="subject">
                          건설 빅데이터 ‘리얼타임 시각화’…글로벌 톱티어 기술력
                          자랑
                        </strong>
                        <p>
                          허준 컨워스 대표는 “건설현장의 데이터는 굉장히 큰 것이
                          특징”이라며, “또한, 모든 공정이 연결돼 있어 분석을
                          위해서는 막대한 계산량이 필요하고, 그와 동시에 높은
                          수준의 3D 그래픽 퀄리티가 요구된다. 컨워스의 솔루션은
                          이 3가지를 만족하는 유일한 솔루션”이라고 말했다.
                        </p>
                        <span className="date">2023년 12월 07일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="news_list" style={{ width: "600px" }}>
                    <a
                      href="http://www.kcllife.kr/2022_autumn/life_story_01.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="thumb">
                        <StaticImage src="../images/kcl_main.png" alt="" />
                      </span>
                      <div className="cont">
                        <strong className="subject">
                          건설현장 안전의 새로운 패러다임 '공간 빅데이터
                          종합관리 원천 기술 개발'
                        </strong>
                        <p>
                          허준 교수는 건설현장의 신속한 디지털 전환과 생산성
                          향상을 통한 선순환적 가치 제공을 목표로 컨워스를
                          창업하여 ‘공간 빅데이터 종합관리 원천기술’의 개발을
                          완료해 다양한 분야를 아우르는 맞춤형 솔루션을 제시하고
                          있다.
                        </p>
                        <span className="date">2022년 9월 30일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="news_list" style={{ width: "600px" }}>
                    <a
                      href="https://www.yonsei.ac.kr/_custom/yonsei/_app/ocx/news/app.jsp?mode=view&ar_seq=20220826215728354013&sr_volume=0&list_mode=list&sr_site=S&pager.offset=36"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="thumb">
                        <StaticImage src="../images/news_thumb5.png" alt="" />
                      </span>
                      <div className="cont">
                        <strong className="subject">
                          [창업톡톡] 건설 산업의 가치를 키우다, (주)컨워스
                        </strong>
                        <p>
                          건설환경공학과 허준 교수는 건설 산업의 문제점을 찾고
                          연구해 혁신적인 생산성 향상을 도출할 수 있는 스마트
                          건설 데이터 플랫폼 기업 ‘(주)컨워스’를 2020년
                          창업했다. 컨워스는 디지털 전환이 가장 느린
                          건설업계에서 단지 입소문만으로 화제를 일으키며 국내
                          유수 건설사들의 파트너사로 프로젝트를 진행하고 있다.
                        </p>
                        <span className="date">2022년 8월 26일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="news_list" style={{ width: "600px" }}>
                    <a
                      href="https://www.mk.co.kr/news/it/view/2022/08/680870"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="thumb">
                        <StaticImage src="../images/news_thumb4.jpeg" alt="" />
                      </span>
                      <div className="cont">
                        <strong className="subject">
                          3D지도·로봇개로 건설현장 사고 막는다
                        </strong>
                        <p>
                          건설 데이터 플랫폼 스타트업 컨워스의 창업자인 허준
                          연세대 건설환경공학과 교수는 "라이다(레이저센서),
                          적외선 카메라, 가스 탐지 등 현장에서 필요한 각종
                          센서를 탑재한 사족보행 로봇개 스팟이 현장을 누비며
                          사람 대신 건설 현장, 공장 등에서 발생할 수 있는 위험
                          등을 점검할 수 있다"면서 "원자력발전소 등 점검이
                          필요하지만 사람이 하기에는 위험한 곳에서도 활용
                          가능하다"고 밝혔다.
                        </p>
                        <span className="date">2022년 8월 2일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="news_list">
                    <a
                      href="https://biz.chosun.com/site/data/html_dir/2020/11/19/2020111901885.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="thumb">
                        <StaticImage src="../images/news_thumb1.png" alt="" />
                      </span>
                      <div className="cont">
                        <strong className="subject">
                          롯데건설·연세대, 로봇 활용한 대용량 건설현장 데이터
                          플랫폼 연구
                        </strong>
                        <p>
                          '네 발 달린 로봇이 건설현장을 누비며 각종 데이터를
                          확보한다. 이 정보는 대용량 클라우드 시스템에 자동
                          저장되는 것 뿐만 아니라 시각화, 분석, 공유된다.
                          롯데건설은 국내 벤처기업 컨워스(Conworth)와 4족 보행
                          로봇 스팟(SPOT)을 활용한 건설 현장용 데이터 플랫폼
                          구축을 공동으로 연구한다고 19일 밝혔다.
                        </p>
                        <span className="date">2020년 11월 19일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="news_list">
                    <a
                      href="https://news.naver.com/main/read.naver?mode=LSD&mid=sec&sid1=101&oid=366&aid=0000711570"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="thumb">
                        <StaticImage src="../images/news_thumb2.png" alt="" />
                      </span>
                      <div className="cont">
                        <strong className="subject">
                          코오롱글로벌 · 컨워스, 건설 현장 데이터 플랫폼 MOU
                          체결
                        </strong>
                        <p>
                          코오롱글로벌㈜가 건설현장 빅데이터 솔루션 스타트업
                          ㈜컨워스와 ‘디지털트윈 통합 건설현장 데이터 플랫폼
                          기술 활용’을 위한 업무협약을 체결했다고 3일 밝혔다.
                          코오롱글로벌의 스마트건설 기술력과 컨워스의 3차원
                          빅데이터 플랫폼 기술을 결합해 건설현장에 최적화된
                          데이터 솔루션을 구현하겠다는 취지다.
                        </p>
                        <span className="date">2021년 5월 3일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="news_list" style={{ width: "550px" }}>
                    <a
                      href="https://news.naver.com/main/read.naver?mode=LSD&mid=sec&sid1=101&oid=277&aid=0005000498"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="thumb">
                        <StaticImage src="../images/news_thumb3.png" alt="" />
                      </span>
                      <div className="cont">
                        <strong className="subject">
                          현대엔지니어링과 컨워스 ‘스마트건설 챌린지 2021’ 공동
                          수상
                        </strong>
                        <p>
                          현대엔지니어링이 국토교통부가 주최하는 ‘스마트건설
                          챌린지 2021’ 스마트안전 및 로보틱스 2개 부문에서
                          입상하며 업계 최고수준의 스마트건설기술 역량을
                          입증했다. 현대엔지니어링은 ‘스마트건설 챌린지
                          2021’에서 로보틱스 부문 최고기술상(국토교통부장관상),
                          스마트안전 부문 혁신상(국토안전관리원 원장상)을
                          수상했다고 16일(화) 밝혔다.
                        </p>
                        <span className="date">2021년 11월 16일</span>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
              </div>

              <div className="swiper_control">
                <div className="swiper-button-prev"></div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next"></div>
              </div>
            </Swiper>
          </div>
          <div className="bg_gray" id="contact">
            <section id="contact">
              <h2>Contact Us</h2>
            </section>
            <p>
            We are hiring talented individuals for roles including<br/><b>3D/2D Map Frontend Developers</b>,
             <b>OpenGL/GLSL Developers</b>,<br/><b>SPOT Robot Control Application Developers</b>, <b>Frontend Leads</b> and <b>Backend Engineers</b>.<br/>Please apply via email.
            </p>
            <p>
              Feel free to contact us via email for any inquiries or assistance
              you may need.
            </p>
            <div className="us_info">
              {/* <RenderAfterNavermapsLoaded
                  ncpClientId="kl54v2uhdw"
                >
                  <NaverMap
                    className="map_box"
                    mapDivId="map"
                    defaultCenter={{ lat: 37.5609569, lng: 126.9356779 }}
                    defaultZoom={16}
                    zoomControl={true}
                  >
                    <Marker
                      position={{ lat: 37.5609569, lng: 126.9356779 }}
                      title="Conworth Inc."
                    />
                  </NaverMap>
                </RenderAfterNavermapsLoaded> */}
              <div className="com_info">
                <ul>
                  <li className="map">
                    #417 Engineering Research Bldg.
                    <br />
                    Yonsei-ro 50, Seodaemun-gu, Seoul, Republic of Korea
                  </li>
                  <li className="mail">
                    <a href="mailto:contact@conworth.io">
                      contact@conworth.io
                      <br />
                      &nbsp;
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MainPage;
